<template>
  <!--style="min-height:600px!important;"-->
  <div id="mainlogin" style="background-size: 3000px 3000px;">
    <div id="back" v-show="isLogin">
      <div class="areaformulario">
        <div id="login">
          <div class="form">
            <input
              v-model="email"
              class="form-input"
              type="email"
              id="email"
              required
              placeholder="Email"
            />
            <input
              v-model="password"
              class="form-input"
              type="password"
              id="password"
              placeholder="Contraseña"
            />
            <p v-if="error" class="error">Email o contraseña incorrectos.</p>
            <input class="form-submit" @click="login" value="Iniciar Sesión" />
            <p>
              <a @click="registrarse" style="float: right;"
                ><span class="colorElemento1 registroLaunch"
                  >Crear una cuenta</span
                ></a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
    <div id="back2" v-show="!isLogin">
      <div class="areaformulario">
        <div id="registro" v-show="!isLogin">
          <div class="form">
            <h2 class="colorElemento1" style="font-weight: bolder;">
              Registrar usuario nuevo
            </h2>

            <input
              v-model="datosRegistro.email"
              type="email"
              class="form-input"
              required
              placeholder="Email"
            />

            <input
              v-model="datosRegistro.password"
              class="form-input"
              type="password"
              size="15"
              required
              placeholder="Contraseña"
            />

            <input
              v-model="passwordRepeat"
              class="form-input"
              type="password"
              id="password-repeat"
              placeholder="Repite la contraseña"
            />

            <input
              v-model="datosRegistro.firstname"
              class="form-input"
              required
              placeholder="Nombre/s"
            />

            <input
              v-model="datosRegistro.lastname"
              class="form-input"
              required
              placeholder="Apellido/s"
            />

            <input
              v-model="datosRegistro.nickname"
              class="form-input"
              placeholder="Nombre para mostrar"
            />
            <p v-if="errorregistro" class="error">{{ register_msg }}</p>
            <p v-if="successregistro" class="colorElemento1">
              {{ register_msg }}
            </p>
            <input class="form-submit" @click="register" value="Registrar" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {_login, _register} from './scripts/auth';
import {mapGetters} from 'vuex';
export default {
  computed: {
    ...mapGetters(['user']),
  },
  data() {
    return {
      email: '',
      password: '',
      datos: {email: '', password: ''},
      error: false,
      isLogin: true,
      isRegister: false,
      datosRegistro: {
        email: '',
        password: '',
        nickname: '',
        firstname: '',
        lastname: '',
      },
      passwordRepeat: '',
      errorregistro: false,
      successregistro: false,
      register_msg: '',
    };
  },
  mounted() {
    let bgImage = require('@/assets/fondo.jpg');
    document.body.style.backgroundImage = `url("${bgImage}")`;
    document.body.style.backgroundRepeat = `no-repeat`;
    document.body.style.backgroundSize = `100% 100%`;
    if (this.user) {
      this.$router.push('/home');
    }
  },
  methods: {
    //pefadad
    login: function() {
      _login(this.email, this.password)
        .then(resultado => {
          //console.log(resultado);
          if (resultado.status === 200) {
            document.body.style.backgroundImage = '';
            this.$store.commit('SET_USER', resultado.data.data.user);
            this.$store.commit('SET_NAV', resultado.data.data.nav);
            /*this.$router.push('/home').then(() => {
              this.$router.go();
            });*/
            try {
              this.$router.go();
              window.location.reload(true);
              // eslint-disable-next-line no-empty
            } catch (err) {}
            //window.location.reload(true);
            //console.log('Resultado: ' + JSON.stringify(err));
          } else {
            this.error = true;
            //console.log('ERROR: -> ' + JSON.stringify(resultado));
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(err => {
          this.error = true;
          console.log('ERROR: -> ' + err);
        });
    },
    register: function() {
      if (this.datosRegistro.nickname === '') {
        this.datosRegistro.nickname =
          this.datosRegistro.firstname + ' ' + this.datosRegistro.lastname;
      }
      if (this.datosRegistro.password === this.passwordRepeat) {
        _register(this.datosRegistro)
          .then(resultado => {
            //console.log(resultado);
            if (resultado.status === 201) {
              this.errorregistro = false;
              this.successregistro = true;
              this.register_msg = 'user created successfully';
              setTimeout(() => (this.isLogin = true), 5000);
              //this.$router.push('/home');
              //console.log('Resultado: ' + JSON.stringify(resultado));
            } else {
              this.errorregistro = true;
              this.register_msg = resultado.msg;
              //console.log('ERROR: -> ' + JSON.stringify(resultado));
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch(err => {
            this.errorregistro = true;
            this.register_msg = "user couldn't be created";
            //console.log('ERROR: -> ' + JSON.stringify(resultado));
          });
      } else {
        this.errorregistro = true;
        this.register_msg = "passwords don't match";
        //console.log('las contraseñas no coinciden');
      }
    },
    registrarse: function() {
      this.isLogin = false;
      this.isRegister = true;
    },
  },
  watch: {
    isLogin: function() {
      //console.log("cambio detectado");
      //console.log(document.getElementById('registro').clienttHeight);
      //document.getElementById('back').style.height =
      //  document.getElementById('registro').offsetHeight + 'px';
    },
  },
};
</script>

<style lang="scss" scoped>
.registroLaunch {
  margin-top: 3rem;
}
.mainlogin {
  margin-top: 20px;
}
.areaformulario {
  margin: 20px auto auto auto;
  width: 50%;
  min-height: 100%;
  padding: 10px;
  padding-top: 30px;
  box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.3);
  background: rgba(19, 35, 47, 0.9);
}

.title {
  text-align: center;
}

.form {
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  max-width: 50%;
  border-radius: 5px;
}
.form-label {
  margin-top: 0.5rem;
  display: flex;
  color: white;
  text-align: left;
  margin-bottom: 0.5rem;
  &:first-of-type {
    margin-top: 2rem;
  }
}
.error {
  margin-top: 2rem;
  color: red;
}
.form-input {
  margin-top: 2rem;
  background: none;
  background-image: none;
  display: flex;
  border: 1px solid white;
  color: white;
  &:focus {
    outline: 0;
    border-color: #1ab188;
  }
  &:first-of-type {
    margin-top: 3rem;
  }
}
.form-submit {
  background: #1ab188;
  border: none;
  text-align: center;
  color: white;
  margin-top: 3rem;
  padding: 1rem 0;
  cursor: pointer;
  margin-left: 15px;
  margin-right: 15px;
  transition: background 0.2s;
  &:hover {
    background: #0b9185;
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 500px) {
  .areaformulario {
    margin: 10px auto auto auto;
    width: 90%;
    min-height: 100%;
    padding: 10px;
    padding-top: 30px;
    box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.3);
    background: rgba(19, 35, 47, 0.9);
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 500px) {
  .areaformulario {
    margin: 10px auto auto auto;
    width: 60%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .areaformulario {
    margin: 10px auto auto auto;
    width: 45%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .areaformulario {
    margin: 25px auto auto auto;
    width: 35%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>
